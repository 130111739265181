import React from "react";

const Logo = () => (
  <svg width="85" height="24" viewBox="0 0 85 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.64749 7.72028L0 7.21678V6.71329H3.78922C4.95344 6.71329 5.87603 6.95944 6.557 7.45175C7.23796 7.94406 7.7981 8.64895 8.23743 9.56643L11.8619 17.2867L13.74 12.386L11.5324 7.72028L9.88492 7.21678V6.71329H13.6741C14.8384 6.71329 15.761 6.95944 16.4419 7.45175C17.1229 7.94406 17.683 8.64895 18.1224 9.56643L21.7468 17.2867L25.2065 8.22378L22.7353 7.21678V6.71329H28.831V7.21678L26.195 8.22378L20.0993 23.8322H19.1108L14.2343 13.4937L10.2144 23.8322H9.22592L1.64749 7.72028Z" fill="#333333"/>
  <path d="M37.7416 24C34.9299 24 32.7442 23.2168 31.1846 21.6503C29.625 20.0615 28.8452 17.8797 28.8452 15.1049C28.8452 13.2923 29.1856 11.7147 29.8666 10.372C30.5476 9.02937 31.4921 8 32.7003 7.28392C33.9304 6.56783 35.3363 6.20979 36.9179 6.20979C38.4994 6.20979 39.8833 6.51189 41.0695 7.11608C42.2777 7.72028 43.2003 8.53706 43.8373 9.56643C44.4963 10.5734 44.8258 11.6923 44.8258 12.9231C44.8258 13.4825 44.7709 13.9636 44.661 14.3664L44.4963 14.9371C43.5517 15.1385 42.5303 15.3063 41.432 15.4406C39.1255 15.7762 36.797 15.9441 34.4466 15.9441C34.4466 18.5399 34.7981 20.3972 35.501 21.5161C36.2039 22.6126 37.1705 23.1608 38.4006 23.1608C39.2573 23.1608 40.0481 22.9594 40.773 22.5566C41.5198 22.1315 42.1019 21.5832 42.5193 20.9119C42.9586 20.2182 43.1783 19.5133 43.1783 18.7972H44.002C44.002 19.6923 43.7384 20.5427 43.2112 21.3483C42.6841 22.1538 41.9482 22.8028 41.0036 23.2951C40.0591 23.765 38.9717 24 37.7416 24ZM34.4466 15.1049C35.8525 15.1049 36.9947 15.049 37.8734 14.9371C38.2468 14.9147 38.6422 14.8587 39.0596 14.7692C39.0815 14.6573 39.1365 14.4112 39.2243 14.0308C39.3342 13.4713 39.3891 12.8783 39.3891 12.2517C39.3891 10.3944 39.1474 9.04056 38.6642 8.19021C38.1809 7.31748 37.5988 6.88112 36.9179 6.88112C35.2704 6.88112 34.4466 9.62238 34.4466 15.1049Z" fill="#333333"/>
  <path d="M47.2829 22.993L49.2598 22.4895V1.00699L47.2829 0.503496V0H51.5663C53.5433 0 54.5318 1.00699 54.5318 3.02098V22.4895L56.5088 22.993V23.4965H47.2829V22.993Z" fill="#333333"/>
  <path d="M58.4806 22.993L60.4576 22.4895V1.00699L58.4806 0.503496V0H62.7641C64.7411 0 65.7296 1.00699 65.7296 3.02098V22.4895L67.7065 22.993V23.4965H58.4806V22.993Z" fill="#333333"/>
  <path d="M77.4216 24C76.0596 24 74.8405 23.6979 73.7641 23.0937C73.2369 22.8028 72.8086 22.4895 72.4791 22.1538C72.1276 22.6462 71.7432 23.0266 71.3259 23.2951C71.1282 23.4294 70.9085 23.5524 70.6669 23.6643H69.8431V17.958H71.1611C71.4467 18.8979 71.864 19.7706 72.4132 20.5762C73.5774 22.2993 75.0272 23.1608 76.7626 23.1608C77.6852 23.1608 78.3771 22.9371 78.8384 22.4895C79.3217 22.0196 79.5633 21.3483 79.5633 20.4755C79.5633 19.7371 79.2338 19.1217 78.5748 18.6294C77.9378 18.1147 76.9273 17.4881 75.5434 16.7497C74.3572 16.1455 73.3907 15.6084 72.6439 15.1385C71.919 14.6462 71.2929 14.042 70.7657 13.3259C70.2605 12.5874 70.0079 11.7259 70.0079 10.7413C70.0079 9.3986 70.568 8.31329 71.6883 7.48532C72.8306 6.63497 74.5769 6.20979 76.9273 6.20979C78.0915 6.20979 79.1679 6.52308 80.1564 7.14965C80.6397 7.44056 81.057 7.74266 81.4085 8.05594C81.694 7.65315 82.0785 7.27273 82.5617 6.91469C82.9351 6.69091 83.1548 6.56783 83.2207 6.54546H84.0445V12.2517H82.7265C82.397 11.2895 81.9906 10.428 81.5073 9.66713C80.409 7.92168 79.102 7.04895 77.5863 7.04895C76.9932 7.04895 76.488 7.27273 76.0706 7.72028C75.6533 8.16783 75.4446 8.72727 75.4446 9.3986C75.4446 10.249 75.796 10.965 76.499 11.5469C77.2019 12.1063 78.2673 12.7552 79.6951 13.4937C80.8593 14.0979 81.7819 14.635 82.4629 15.1049C83.1658 15.5524 83.7589 16.1119 84.2422 16.7832C84.7474 17.4545 85 18.2378 85 19.1329C85 22.3776 82.4739 24 77.4216 24Z" fill="#333333"/>
  </svg>

);

export default Logo;
